<template>
  <div class="payer_detail credit_holder_main_area">
    <div class="row">
      <div class="col-md-6" v-if="device == 'desktop'">
        <div class="credit_holder_image_box">
          <img :src="`${imageDomain}/assets/img/new2.jpg`" alt="payment-image" title="" width="544" height="370"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="credit_holder_form_area">
          <div class="header_box">
            <h3>
              <span class="icon"><i class="fas fa-credit-card"></i></span>
              {{ $t('booking.payer-details') }}
            </h3>
          </div>
          <div class="body_box pt-2">
            <div class="d-flex">
              <div class="form-group col-6 m-0 p-0">
                <input
                  required
                  type="text"
                  :state="stateFirstName"
                  :class="['form-control', !stateFirstName ? 'danger' : '']"
                  :placeholder="$t('booking.first-name')"
                  v-model="firstNamePayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="stateFirstName"><i class="fas fa-check"></i></div>
              </div>
              <div class="form-group col-6 m-0 p-0">
                <input
                  type="text"
                  :class="['form-control', !stateLastName ? 'danger' : '']"
                  :placeholder="$t('booking.last-name')"
                  v-model="lastNamePayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="stateLastName"><i class="fas fa-check"></i></div>
                <!-- <div class="form-icon"><i class="fas fa-user"></i></div> -->
              </div>
            </div>
            <div class="form-group p-2">
              <label>{{ $t('booking.phone') }}</label>
              <div class="w-100 position-relative ">
                <input
                  type="text"
                  :class="['form-control', !statePhone ? 'danger' : '']"
                  :placeholder="$t('booking.phone')"
                  v-model="phonePayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="statePhone"><i class="fas fa-check"></i></div>
                <!-- <div class="form-icon"><i class="fas fa-user"></i></div> -->
              </div>
            </div>
            <div class="form-group p-2">
              <label>{{ $t('booking.email') }}</label>
              <div class="w-100 position-relative ">
                <input
                  type="email"
                  :class="['form-control', !stateEmail ? 'danger' : '']"
                  :placeholder="$t('booking.email')"
                  v-model="emailPayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="stateEmail"><i class="fas fa-check"></i></div>
                <!-- <div class="form-icon"><i class="fas fa-user"></i></div> -->
              </div>
            </div>
            <div class="form-group p-2">
              <label>{{ $t('booking.id-card') }}</label>
              <div class="w-100 position-relative ">
                <input
                  type="text"
                  :class="['form-control', !stateIDCardNumber ? 'danger' : '']"
                  :placeholder="$t('booking.id-card')"
                  v-model="idCardNumberPayer"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="stateIDCardNumber"><i class="fas fa-check"></i></div>
                <!-- <div class="form-icon"><i class="fas fa-user"></i></div> -->
              </div>
            </div>
            <div class="form-group p-1">
              <label>{{ $t('booking.no-of-payment') }}</label>
              <div class="w-100 position-relative ">
                <b-form-select
                  v-model="NoOfPayment"
                  :options="paymentOptions"
                  @change="updatingPayerInfo"
                  class="form-control"
                ></b-form-select>
              </div>
            </div>
            <!-- <div class="form-group p-1" v-if="isMaccabiAgency">
              <label>{{ $t('booking.split-payment') }}</label>
              <div class="w-100 position-relative ">
                <b-form-select
                  v-model="splitPriceCount"
                  :options="splitCardList"
                  @change="updatingSplitCard"
                  class="form-control"
                ></b-form-select>
              </div>
            </div> -->
            <div class="form-group p-1" v-if="isMaccabiAgency && availSplitCard">
              <label>{{ $t('booking.split-payment') }}</label>
              <div class="w-100 position-relative split-card-radio">
                <b-form-radio-group
                  plain
                  id="radio-group"
                  v-model="splitPriceCount"
                  :options="splitCardList"
                  name="split-card"
                  @change="updatingSplitCard"
                ></b-form-radio-group>
              </div>
            </div>
            <label class="w-100 d-sm-block d-md-none">{{ $t('booking.card-validity') }}</label>
            <!-- <div class="form-group p-1">
              <label class="w-100">{{ $t('booking.card-validity') }}</label>
              <b-form-select
                v-model="CreditCardExpirMonth"
                :options="months"
                @change="updatingPayerInfo"
              ></b-form-select>
              <b-form-select
                v-model="CreditCardExpirYear"
                :options="years"
                @change="updatingPayerInfo"
              ></b-form-select>
            </div>
            <div class="form-group p-2">
              <label>{{ $t('booking.no-of-payment') }}</label>
              <div class="w-100 position-relative ">
                <input
                  type="text"
                  :class="['form-control', !stateNoOfPayment ? 'danger' : '']"
                  :placeholder="$t('booking.no-of-payment')"
                  v-model="NoOfPayment"
                  @change="updatingPayerInfo"
                />
                <div class="icon-form" v-if="stateNoOfPayment"><i class="fas fa-check"></i></div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <b-modal id="confirmationModal" centered :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true">
      <template #modal-header>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('booking.notice') }}</h3>
        </div>
      </template>
      <template>
        <div class="w-100" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <span >
            {{ $t('booking.you-selected-2-payment') }}
          </span>
        </div>
      </template>
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok();">
          {{ $t('booking.than') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormSelect, BFormRadioGroup, BModal } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';
import gMixin from '@/utils/mixins';
import { validateEmail } from '@/utils/api';

export default {
  mixins: [imageUrlMixin, gMixin],
  props: {
    paxCount: {
      type: Number,
      default: 0,
    },
    nisTotalPrice: {
      type: Number,
      default: 0,
    },
    couponInfo: {
      type: Array,
      default: () => [],
    },
    discountedNisPrice: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BFormSelect,
    BFormRadioGroup,
    BModal,
  },
  data() {
    return {
      years: [],
      months: [
        { value: '01', text: this.$t('monthName.jan') },
        { value: '02', text: this.$t('monthName.feb') },
        { value: '03', text: this.$t('monthName.mar') },
        { value: '04', text: this.$t('monthName.apr') },
        { value: '05', text: this.$t('monthName.may') },
        { value: '06', text: this.$t('monthName.jun') },
        { value: '07', text: this.$t('monthName.jul') },
        { value: '08', text: this.$t('monthName.aug') },
        { value: '09', text: this.$t('monthName.sep') },
        { value: '10', text: this.$t('monthName.oct') },
        { value: '11', text: this.$t('monthName.nov') },
        { value: '12', text: this.$t('monthName.dec') },
      ],
      firstNamePayer: '',
      lastNamePayer: '',
      phonePayer: '',
      emailPayer: '',
      idCardNumberPayer: '',
      // CreditCardExpirYear: '',
      // CreditCardExpirMonth: '01',
      // NoOfPayment: '1',

      // firstNamePayer: "test",
      // lastNamePayer: "test",
      // phonePayer: "12345545",
      // emailPayer: "test@yahoo.com",
      // idCardNumberPayer: "011111119",
      // CreditCardExpirYear: "25",
      // CreditCardExpirMonth: "12",
      NoOfPayment: '1',
      splitPriceCount: '1',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      devMode: 'GET_MODE',
      stagingMode: 'GET_STAGING_MODE',
      agencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      isTargetAgency: 'GET_IS_TARGET_AGENCY',
      optionsMaccabi: 'GET_OPTIONS_MACCABI',
      isMenoraAgency: 'GET_IS_MENORA_AGENCY',
      paymentOptionParams: 'GET_PAYMENT_OPTION_PARAMS',
    }),
    paymentOptions() {
      const { minPaymentsForCredit, maxPayments } = this.paymentOptionParams;
      const listPaymentOption = [{ value: '1', text: `1 ${this.$t('booking.payment')}` }];
      for (let i = 2; i <= maxPayments; i += 1) {
        listPaymentOption.push({ value: i, text: `${i} ${i >= minPaymentsForCredit ? this.$t('booking.credit-payments') : this.$t('booking.interest-free-payment')}` });
      }

      return listPaymentOption;
    },
    stateFirstName() {
      return !!this.firstNamePayer && this.firstNamePayer !== '';
    },
    stateLastName() {
      return !!this.lastNamePayer && this.lastNamePayer !== '';
    },
    stateEmail() {
      // return !!this.emailPayer && this.emailPayer !== '' && !this.emailPayer.includes('..');
      return validateEmail(this.emailPayer);
    },
    statePhone() {
      return !!this.phonePayer && this.phonePayer !== '';
    },
    stateIDCardNumber() {
      return !!this.idCardNumberPayer && this.idCardNumberPayer !== '';
    },
    // stateCreditCardExpireYear() {
    //   return !!this.CreditCardExpirYear;
    // },
    // stateCreditCardExpireMonth() {
    //   return !!this.CreditCardExpirMonth;
    // },
    // stateNoOfPayment() {
    //   return !!this.NoOfPayment;
    // },
    splitCardList() {
      const { nisTotalPrice, paxCount, couponInfo, discountedNisPrice } = this;
      const roundPrice = Math.round(nisTotalPrice);
      return Array.from({ length: paxCount }, (_, i) => i).map((inx) => {
        const splitCount = inx + 1;
        const splitPriceArray = splitCount === 1 ? [discountedNisPrice] : this.splitNumber(roundPrice, splitCount).map((price, i) => (price - (couponInfo[i] || 0)));
        const splitPriceText = splitPriceArray.join(' ,₪');
        return {
          value: splitCount,
          text: splitCount === 1 ? this.$t('booking.split-one-card', { priceLabel: `₪${splitPriceText}` })
            : this.$t('booking.split-more-cards', { priceLabel: `₪${splitPriceText}`, cardsCount: splitCount }),
        };
      });
    },
    availSplitCard() {
      return this.optionsMaccabi.availSplitCard;
    },
  },
  watch: {
    lang: 'updateLabelWithLang',
    idCardNumberPayer() {
      const length = 9 - String(this.idCardNumberPayer).length;
      if (length > 0) {
        const str = new Array(length + 1).join('0');
        this.idCardNumberPayer = `${str}${this.idCardNumberPayer}`;
      } else if (length < 1) {
        this.idCardNumberPayer = this.idCardNumberPayer.substr(-length);
      }
    },
    splitPriceCount() {
      if (this.splitPriceCount === 2) { this.$bvModal.show('confirmationModal'); }
    },
  },
  created() {
    const date = new Date(),
      currYear = date.getFullYear();
    for (let i = 0; i < 10; i += 1) {
      this.years.push({
        value: String(currYear - 2000 + i),
        text: String(currYear + i),
      });
    }
    // this.CreditCardExpirYear = this.years[0].value;
    const retry = window.sessionStorage.getItem('retryState'),
      dataBook = JSON.parse(window.sessionStorage.getItem('BookingDetailInfo')),
      { payer } = dataBook.data,
      { sendingStage } = dataBook;
    if (retry !== 'null' && sendingStage !== 'init') {
      if (payer.name.length > 0) {
        const name = payer.name.split(' ');
        this.firstNamePayer = name[0];
        this.lastNamePayer = name[1];
      }
      this.phonePayer = payer.mobile;
      this.emailPayer = payer.email;
      this.idCardNumberPayer = payer.IdenticationNumber;
      // this.CreditCardExpirYear = payer.CreditCardExpirYear || this.years[0].value;
      // this.CreditCardExpirMonth = payer.CreditCardExpirMonth || '01';
      this.NoOfPayment = Number(payer.NoOfPayment);
      this.updatingPayerInfo();
    }
    if (this.devMode || this.stagingMode) {
      if (this.firstNamePayer === '') this.firstNamePayer = 'test';
      if (this.lastNamePayer === '') this.lastNamePayer = 'test';
      if (this.emailPayer === '') this.emailPayer = 'ofirfishler@gmail.com';
      if (this.phonePayer === '') this.phonePayer = '543320148';
      if (this.idCardNumberPayer === '') this.idCardNumberPayer = '000000018';
      if (this.CreditCardExpirYear === '') this.CreditCardExpirYear = '2025';
      if (this.CreditCardExpirMonth === '') this.CreditCardExpirMonth = '12';
    }
    this.updatingPayerInfo();
  },
  methods: {
    updateLabelWithLang() {
      this.months = [
        { value: '01', text: this.$t('monthName.jan') },
        { value: '02', text: this.$t('monthName.feb') },
        { value: '03', text: this.$t('monthName.mar') },
        { value: '04', text: this.$t('monthName.apr') },
        { value: '05', text: this.$t('monthName.may') },
        { value: '06', text: this.$t('monthName.jun') },
        { value: '07', text: this.$t('monthName.jul') },
        { value: '08', text: this.$t('monthName.aug') },
        { value: '09', text: this.$t('monthName.sep') },
        { value: '10', text: this.$t('monthName.oct') },
        { value: '11', text: this.$t('monthName.nov') },
        { value: '12', text: this.$t('monthName.dec') },
      ];
    },
    updatingPayerInfo() {
      const payer = {
        firstNamePayer: this.firstNamePayer,
        lastNamePayer: this.lastNamePayer,
        phonePayer: this.phonePayer,
        emailPayer: this.emailPayer,
        idCardNumberPayer: this.idCardNumberPayer,
        // CreditCardExpirYear: this.CreditCardExpirYear,
        // CreditCardExpirMonth: this.CreditCardExpirMonth,
        NoOfPayment: Number(this.NoOfPayment),
        available: false,
      };
      payer.available = this.availablePayerInformation();
      this.$emit('updatePayer', payer);
    },
    availablePayerInformation() {
      if (this.stateFirstName
        && this.stateLastName
        && this.stateEmail
        && this.statePhone
        && this.idCardNumberPayer
        // && this.stateCreditCardExpireYear
        // && this.stateCreditCardExpireMonth
        // && this.stateNoOfPayment
      ) {
        return true;
      } else {
        return false;
      }
    },
    updatingSplitCard() {
      this.$emit('updateSplitCount', this.splitPriceCount);
    },
  },
};
</script>

<style scoped>
.header_box {
  text-align: right;
}

.credit_holder_main_area {
  margin: 30px 0px 0px;
}

.credit_holder_image_box {
  height: 370px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
}

.credit_holder_image_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.credit_holder_form_area .header_box h3 {
  margin: 0 0;
  color: #ffffff;
  font-size: 22px;
  text-transform: uppercase;
}

.credit_holder_form_area {
  overflow: hidden;
  border-radius: 10px;
}

.credit_holder_form_area .header_box {
  padding: 25px 25px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 98, 172, 1) 0%,
    rgba(38, 135, 209, 1) 100%
  );
}

.credit_holder_form_area .body_box {
  padding: 5px 10px;
  border: 1px solid #d3d3d3;
  border-top: 0px;
}

.payer_detail .form-group input,
.payer_detail .form-group select {
  margin: 0px 5px;
}

.form-group {
  display: flex;
  margin: 8px 0px;
}

.form-group label {
  line-height: 20px;
  padding-top: 10px;
  width: 40%;
}

.credit_holder_form_area .body_box label {
  color: rgb(151, 151, 155);
  font-size: 14px;
  margin-bottom: 0px;
}

.form-group .icon-form{
    content: '';
    position: absolute;
    bottom: 5px;
    left: 14px;
    color: #28a745;
}

.danger {
  border-color: #FF5733;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(233 102 102 / 60%);
}

@media (max-width: 479px) {
  .credit_holder_main_area {
    margin: 5px 0px 0px;
  }
  .price_details_main_body {
    padding: 0px 0px;
  }

  .credit_holder_form_area .header_box {
    background: #fff;
    padding: 5px 5px;
  }

  .credit_holder_form_area .header_box h3 {
    padding: 0 0 7px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    border-bottom: 2px solid #bfbfbf;
    margin-bottom: 10px;
    position: relative;
    padding-right: 25px;
  }

  .body_box .form-group label {
    display: none;
  }

  .invalid-feedback{
    background-color: white;
    border-radius: 2px;
  }
  .form-control.is-valid, .form-control.is-invalid{
      background-position: left calc(0.375em + 0.1875rem) center;
  }
}
</style>

<style>
  .form-group .split-card-radio label {
    margin-bottom: 0;
    margin-right: 1rem;
  }
</style>
